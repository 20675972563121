import React, {Component} from "react"
import Layout from "components/Layout/Layout"
import PageHeader from "components/shared/PageHeader"
import CameraAnimation from "../../components/Animations/CameraAnimation"
import VideoBlock from "../../components/VideoMusicPhotoPage/VideoBlock";
import AudioBlock from "../../components/VideoMusicPhotoPage/AudioBlock";
import PhotosBlock from "../../components/VideoMusicPhotoPage/PhotosBlock";
import GreatScottBlock from "../../components/HomePage/GreatScottBlock";
import {graphql, StaticQuery} from "gatsby"

class MusicPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			musicStopped: false,
			videoStopped: false
		}

		this.stopMusic = this.stopMusic.bind(this);
		this.stopVideo = this.stopVideo.bind(this);
	}

	stopMusic = (musicStopped) => {
		this.setState({
			musicStopped
		})
	}

	stopVideo = (videoStopped) => {
		this.setState({
			videoStopped
		})
	}

	componentDidUpdate(prevProps,prevState) {
		if (prevState.musicStopped !== this.state.musicStopped) {
			this.setState({
				musicStopped: this.state.musicStopped
			})
		}
	}

	render() {
		return (
			<Layout title="Music, Video & Photos | London | Back to the Future the Musical" description="The latest videos and photos from Back to the Future The Musical at the Adelphi Theatre in London.">
				<PageHeader
					imageXs={this.props.data.imageXs}
					imageSm={this.props.data.imageSm}
					imageLg={this.props.data.imageLg}
					title="Music, Video & Photos" />
				<AudioBlock
					stopMusic={this.stopMusic}
					stopVideo={this.stopVideo}
					videoStopped={this.state.videoStopped}
					musicStopped={this.state.musicStopped}
				/>
				<VideoBlock
					stopMusic={this.stopMusic}
					stopVideo={this.stopVideo}
					videoStopped={this.state.videoStopped}
					musicStopped={this.state.musicStopped}
				/>
				<CameraAnimation/>
				<PhotosBlock/>
			</Layout>
		)
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
            query {
                imageXs: file(relativePath: { eq: "576.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.png" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
		render={data => (
			<MusicPage
				{...props}
				data={data}/>
		)}
	/>
)
